<template>
    <div class='page'>
        <div class="body1">
            <img class="product1" src="@/assets/images/product1.png" alt="">
            <div class="info txt_center wow fadeInDown">
                <div class="title">{{ $t('product.value1') }}</div>
                <div class="desc">{{ $t('product.value2') }}</div>
            </div>
        </div>
        <!-- 产品展示 -->
        <div class="body2 dis_flex">
            <img class="product2" src="@/assets/images/product2.png" alt="">
            <div class="left wow fadeInLeft">
                <div class="title dis_flex items_center ">
                    <img src="@/assets/images/index2.png" alt="">
                    {{ $t('product.value3') }}
                </div>
                <!-- tab切换 -->
                <div class="tabs">
                    <div class="tab" :class="{ 'active': current == index }" v-for="(item, index) in tabs" :key="index"
                        @click="changeTab(index)">{{ $i18n.locale == 'zh' ? item.title : item.titleEn }}</div>
                </div>
            </div>
            <div class="right">
                <div class="items dis_flex wrap jus_between" v-if="categoryProductList.length">
                    <div class="item dis_flex" v-for="(item, index) in categoryProductList" :key="index"
                        @click="$goPage(`/productDetail?id=${item.id}`)">
                        <div class="imgBox">
                            <img v-if="item.img" :src="item.img" alt="">
                            <img v-else src="@/assets/images/product3.png" alt="">
                            <div class="hover txt_center">{{ $t('product.value11') }}</div>
                        </div>
                        <div class="item-right">
                            <div class="title sheng_2">{{ $i18n.locale == 'zh' ? item.title : item.titleEn }}</div>
                            <div class="desc sheng_3">{{ $i18n.locale == 'zh' ? item.introduction : item.introductionEn }}
                            </div>
                            <div class="flex_btn">{{ $t('product.value9') }}</div>
                        </div>
                    </div>
                </div>
                <div class="empty" v-else>
                    {{ $t('empty') }}
                </div>
                <el-pagination background layout="prev, pager, next" @current-change="currentChange" :current-page="page"
                    :page-size="size" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { productCategoryAll, productList } from "@/api"
export default {
    data() {
        return {
            current: 0,
            page: 1,
            total: 0,
            size: 10,
            tabs: [],
            categoryProductList: []
        }
    },
    created() {
        this.$nextTick(() => {   //页面渲染完，在执行
            new this.$wow({
                live: false
            }).init()
        })
        this.productCategoryAll()
    },
    methods: {
        productCategoryAll() {
            productCategoryAll().then(res => {
                if (res.code == 0) {
                    res.data.unshift({
                        id: null,
                        title: '全部',
                        titleEn: 'All'
                    })
                    this.tabs = res.data
                    this.getProductList()

                }
            })
        },
        changeTab(index) {
            this.current = index
            this.page = 1
            this.getProductList()

        },
        currentChange(val) {
            this.page = val
            this.getProductList()

        },
        getProductList() {
            productList({ categoryId: this.tabs[this.current].id, page: this.page, size: this.size }).then(res => {
                if (res.code == 0) {
                    res.data.records.map(item => {
                        item.img = this.$utils.getImgUrl(item.img)
                    })
                    this.categoryProductList = res.data.records
                    this.total = res.data.total
                }
            })
        }
    },
}
</script>
<style scoped lang='scss'>
.body1 {
    position: relative;
    width: 100%;
    height: 800px;
    padding-top: 428px;
    box-sizing: border-box;

    .product1 {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .info {
        color: #FFFFFF;
        position: relative;

        .title {
            font-size: 60px;
            font-weight: bold;
        }

        .desc {
            font-size: 22px;
        }
    }
}

.body2 {
    padding: 100px 150px 44px;
    min-height: 800px;
    box-sizing: border-box;
    position: relative;

    .product2 {
        position: absolute;
        left: 0;
        top: 460px;
        width: 260px;
        object-fit: cover;
    }

    .left {
        z-index: 1;

        .title {
            font-size: 36px;
            font-weight: bold;
            margin-bottom: 38px;

            img {
                width: 72px;
                height: 72px;
                margin-right: 8px;
            }
        }

        .tabs {
            width: 175px;

            .tab {
                color: #818181;
                font-size: 20px;
                font-weight: bold;
                padding: 14px 0;
                border-top: 2px solid #D7D7D7;
                cursor: pointer;

                &.active {
                    color: #101F93;
                }
            }
        }
    }

    .right {
        margin-left: 60px;
        flex: 1;
        .empty{
            width: 100%;
            height: 300px;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            margin-top: 150px;
        }
        .item {
            width: 47%;
            margin-bottom: 85px;
            cursor: pointer;

            &:hover {
                .hover {
                    display: block !important;
                }

                .item-right {
                    .flex_btn {
                        transition: .3s;
                        background: #00239C !important;
                        border-color: #00239C !important;
                        color: #fff !important;
                    }
                }

            }

            .imgBox {
                width: 260px;
                height: 260px;
                position: relative;
                border-radius: 16px;
                overflow: hidden;
                flex-shrink: 0;
                margin-right: 54px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .hover {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: none;
                    width: 100%;
                    height: 100%;
                    line-height: 260px;
                    background: rgba($color: #BAD4DD, $alpha: .7);
                    color: #FFFFFF;
                    font-size: 30px;
                    font-weight: bold;
                }
            }

            .item-right {
                position: relative;

                .title {
                    font-size: 26px;
                    font-weight: bold;
                    margin-bottom: 14px;
                }

                .desc {
                    color: #4D4D4D;
                    font-size: 16px;
                }

                .flex_btn {
                    position: absolute;
                    bottom: 0;
                    // right: 0;
                    width: 140px;
                    height: 50px;
                    border-radius: 33px;
                    border: 1px solid #00239C;
                    color: #00239C;
                    font-size: 16px;
                    font-weight: bold;
                    box-sizing: border-box;

                }
            }
        }

        .el-pagination {
            text-align: right;

        }
    }
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #001E77;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
    color: #fff;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #001E77;
}

::v-deep .el-pagination button {
    min-width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
}

::v-deep .el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    min-width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
}

::v-deep .el-pager li {
    min-width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
}



@media only screen and (min-width: 1400px) and (max-width: 1700px) {
    .body2 {
        .product2 {
            width: 200px;
        }

        .left {
            .title {
                font-size: 32px;
            }

            .tabs {
                width: 150px;
            }
        }

        .right {
            .item {
                .imgBox {
                    width: 220px;
                    height: 220px;

                    .hover {
                        line-height: 220px;
                        font-size: 28px;
                    }
                }

                .item-right {
                    .title {
                        font-size: 24px;
                    }

                    .desc {
                        font-size: 14px;
                    }

                    .flex_btn {
                        width: 120px;
                        height: 42px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .body2 {
        .product2 {
            width: 160px;
        }

        .left {
            .title {
                font-size: 26px;
            }

            .tabs {
                width: 130px;
            }
        }

        .right {
            .item {
                .imgBox {
                    width: 180px;
                    height: 180px;

                    .hover {
                        line-height: 180px;
                        font-size: 26px;
                    }
                }

                .item-right {
                    .title {
                        font-size: 22px;
                    }

                    .desc {
                        font-size: 14px;
                    }

                    .flex_btn {
                        width: 100px;
                        height: 35px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>
